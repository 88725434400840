module.exports = {
	defaultTitle: "People's budget Harrisburg",
	logo: 'C:Users/Risha/HBG-Site/HBG-Site/src/assets/$transparent-background.png',
	author: 'Rishabh Bajpai',
	url: 'https://v1.nocodeapi.com/rishabhbajpai/google_sheets/ONPcQhrfxiYtVEvt?tabId=Sheet1',
	legalName: "People's budget Harrisburg",
	defaultDescription: 'Get Copy from team',
	socialLinks: {
		twitter: 'http://www.twitter.com/smakosh',
		github: 'https://github.com/smakosh',
		linkedin: 'https://www.linkedin.com/in/ismail-ghallou-630149122/',
		instagram: 'https://instagram.com/smakosh19',
		youtube:
			'https://www.youtube.com/playlist?list=PLkC2DAhbH7qL91gBbSLnLnauRXXG4CKTJ',
		google: 'https://plus.google.com/u/0/+IsmailSmakoshGhallou',
	},
	googleAnalyticsID: 'UA-88875900-4',
	themeColor: '#6b63ff',
	backgroundColor: '#6b63ff',
	social: {
		facebook: 'appId',
		twitter: '@smakosh',
	},
	address: {
		city: 'Harrisburg',
		region: 'PA',
		country: 'USA',
		zipCode: 'ZipCode',
	},
	contact: {
		email: 'email',
		phone: 'phone number',
	},
	foundingDate: '2021',
};
